/**
 * @generated SignedSource<<4360ee4bbd25a0f9b84777b4953372e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingFunctions_itemSearch$data = {
  readonly trackedAbTests: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "SbSharedTrackingFunctions_itemSearch";
};
export type SbSharedTrackingFunctions_itemSearch$key = {
  readonly " $data"?: SbSharedTrackingFunctions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingFunctions_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SbSharedTrackingFunctions_itemSearch"
};

(node as any).hash = "1cff9e9a983601a69c89032258e279cd";

export default node;
