import { trackEvent, eventNameConstants } from 'dibs-tracking';

type Args = {
    isFollowing: boolean;
    label: string;
    noninteraction?: number;
};

const trackFollowButtonClick = ({ isFollowing, label, noninteraction }: Args): void => {
    const category = 'search favoriting';
    const action = `search ${isFollowing ? 'added' : 'removed'}`;

    trackEvent({
        category,
        eventName: eventNameConstants.EVENT_WISHLIST,
        action,
        interaction_type: category,
        label,
        step_interaction_name: action,
        value: 0,
        noninteraction,
    });
};

export { trackFollowButtonClick };
