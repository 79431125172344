/**
 * @generated SignedSource<<86df610a770f9b1caacdceb1c61630dc>>
 * @relayHash 69798141d7e753900735746c439b9ec8
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/SbSharedRefineMenuTypeaheadQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuTypeaheadQuery$variables = {
  facetName?: string | null;
  hasQuery: boolean;
  searchString?: string | null;
  uriRef?: string | null;
  userCountryCode?: string | null;
};
export type SbSharedRefineMenuTypeaheadQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeahead_viewer">;
  };
};
export type SbSharedRefineMenuTypeaheadQuery = {
  response: SbSharedRefineMenuTypeaheadQuery$data;
  variables: SbSharedRefineMenuTypeaheadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "facetName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchString"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uriRef"
},
v4 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v5 = {
  "kind": "Variable",
  "name": "facetName",
  "variableName": "facetName"
},
v6 = {
  "kind": "Variable",
  "name": "searchString",
  "variableName": "searchString"
},
v7 = {
  "kind": "Variable",
  "name": "uriRef",
  "variableName": "uriRef"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SbSharedRefineMenuTypeaheadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "hasQuery",
                "variableName": "hasQuery"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "userCountryCode",
                "variableName": "userCountryCode"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SbSharedRefineMenuTypeahead_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "SbSharedRefineMenuTypeaheadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasQuery",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "countryCode",
                    "variableName": "userCountryCode"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "concreteType": "ItemFacetsQueryConnection",
                "kind": "LinkedField",
                "name": "itemFacetSearch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemFacetsQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterValueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "urlLabel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkReference",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/SbSharedRefineMenuTypeaheadQuery",
    "metadata": {},
    "name": "SbSharedRefineMenuTypeaheadQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2810cc5020382bd4a02c8854216cadc4";

export default node;
