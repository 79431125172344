import { trackEvent, eventNameConstants } from 'dibs-tracking';

export const locations = {
    SEARCH_BROWSE: 'SEARCH_BROWSE',
    SOLD_PDP: 'SOLD_PDP',
    UNLISTED_PDP: 'UNLISTED_PDP',
} as const;

export const followSourceLabels = {
    [locations.SOLD_PDP]: 'sold pdp',
    [locations.UNLISTED_PDP]: 'unlisted pdp',
} as const;

export const authLabels = {
    [locations.SEARCH_BROWSE]: 'save search - search/browse page',
    [locations.SOLD_PDP]: 'save search - sold pdp',
    [locations.UNLISTED_PDP]: 'save search - unlisted pdp',
} as const;

const category = 'search favoriting';

export function trackFollowSearchAdded({
    location,
}: {
    location: keyof typeof followSourceLabels;
}): void {
    const action = 'search added';
    trackEvent({
        category,
        eventName: eventNameConstants.EVENT_WISHLIST,
        action,
        interaction_type: category,
        label: followSourceLabels[location],
        step_interaction_name: action,
    });
}

export function trackFollowSearchRemoved({
    location,
}: {
    location: keyof typeof followSourceLabels;
}): void {
    const action = 'search removed';
    trackEvent({
        category,
        eventName: eventNameConstants.EVENT_WISHLIST,
        action,
        interaction_type: category,
        label: followSourceLabels[location],
        step_interaction_name: action,
    });
}
